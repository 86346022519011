<template>
  <!--begin::Enquiry Listing-->
  <div class="enquiry-template">
    <PageHeaderCount
      moduleType="enquiry"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_enquiries"
      countkey="enquiry_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'enquiry-listing'"
      v-if="getPermission('enquiry:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 my-auto">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('enquiry:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in bulkActions">
                        <v-list-item
                          link
                          v-on:click="updateBulkAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                        <!--   <v-list-item-subtitle
                          v-text="item.description"
                        ></v-list-item-subtitle> -->
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_enquiries
                          }}</template>
                          <template v-else>{{
                            item.enquiry_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-flex
                class="pt-0 pr-0 justify-flex-end d-flex enquiry-listing-button-right"
              >
                <template v-if="getPermission('enquiry:create')">
                  <v-btn
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('enquiry.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  v-if="false"
                  :disabled="dataLoading"
                  class="mx-2 custom-bold-button"
                  v-on:click="exportEnquiry"
                  color="cyan white--text"
                >
                  <v-icon dark left>mdi-database-export</v-icon> Export
                </v-btn>
                <v-btn
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <v-btn
                  v-if="false && !isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'enquiry',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button pr-0"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn>
                <v-menu
                  v-if="false"
                  content-class="custom-menu-list"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        :class="{ 'line-active': item.sort }"
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" small left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                          <template v-if="item.sort">
                            <v-icon
                              v-if="item.sort == 'asc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-ascending</v-icon
                            >
                            <v-icon
                              v-if="item.sort == 'desc'"
                              small
                              right
                              color="cyan"
                              >mdi-sort-descending</v-icon
                            >
                          </template>
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="260"
                  offset-y
                  left
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          v-on:change="updateVisible()"
                          hide-details
                          class="pt-2 mt-0"
                        ></v-checkbox>
                        <v-icon
                          class="draggable-drag-icon"
                          small
                          right
                          color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <v-menu offset-y left v-if="false">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          depressed
                          :disabled="dataLoading"
                          class="ml-2 custom-bold-button"
                          color="cyan white--text"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          {{ entityObject.name }}
                        </v-btn>
                      </template>
                      <span>Entity</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in entityList"
                      :key="index"
                      link
                      v-on:click="updateEntity(item)"
                    >
                      <v-list-item-title class="font-weight-600">{{
                        item.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <PageTips v-if="false" module="enquiry"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Enquiry"
          :basicSearchFields="[
            'barcode',
            'reference',
            'call_no',
            'title',
            'door_location',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{ 'checkbox-width-limit': cols.checkbox }"
                      :style="{ 'max-width': cols.width }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="rowData.length > 0">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="enquiry-listing-row"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        :style="{ 'max-width': cols.width }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <div class="d-inline-grid">
                            <Barcode
                              route="enquiry.detail"
                              :barcode="data.barcode"
                              :id="data.id"
                            ></Barcode>
                          </div>
                          <div v-if="false">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  label
                                  small
                                  :color="data?.priority_relation?.color"
                                  :text-color="
                                    data?.priority_relation?.textcolor
                                  "
                                  class="mr-2 text-uppercase font-weight-600"
                                  >{{
                                    data?.priority_relation?.text.charAt(0)
                                  }}</v-chip
                                >
                              </template>
                              <span
                                >{{
                                  data?.priority_relation?.text
                                }}
                                Priority</span
                              >
                            </v-tooltip>
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  label
                                  small
                                  :color="data?.impact_relation?.color"
                                  :text-color="data?.impact_relation?.textcolor"
                                  class="text-uppercase font-weight-600"
                                  >{{
                                    data?.impact_relation?.text.charAt(0)
                                  }}</v-chip
                                >
                              </template>
                              <span
                                >{{ data?.impact_relation?.text }} Impact</span
                              >
                            </v-tooltip>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'enquiry_info'">
                          <div class="enquiry-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Title: </b>
                              <show-value
                                :object="data"
                                object-key="title"
                                label="Title"
                              />
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Door Location/Serial No.: </b>
                              <show-value
                                :object="data"
                                object-key="door_location"
                                label="Door Location/Serial No."
                              />
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Call No.: </b>
                              <show-value
                                :object="data"
                                object-key="call_no"
                                label="Call No."
                              />
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Call Date: </b>{{ formatDate(data.call_date) }}
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'customer_info'">
                          <div class="enquiry-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              <show-value
                                :object="data"
                                object-key="customer_relation.display_name"
                                label="display name"
                              />
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company: </b>
                              <show-value
                                :object="data"
                                object-key="customer_relation.company_name"
                                label="company"
                              />
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>
                              <show-value
                                :object="data"
                                object-key="billing_person_relation.primary_email"
                                label="email"
                              />
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <show-value
                                :object="data"
                                object-key="billing_person_relation.primary_phone"
                                label="phone"
                              />
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'status'">
                          <div class="text-left">
                            <v-tooltip top content-class="custom-top-tooltip">
                              <template v-slot:activator="{ on, attrs }">
                                <v-chip
                                  v-bind="attrs"
                                  v-on="on"
                                  label
                                  style="height: 22px"
                                  :color="data?.enq_status_color"
                                  :text-color="data?.enq_status_textcolor"
                                  class="ml-2 text-uppercase font-weight-600"
                                  >{{ data?.enq_status_text }}</v-chip
                                >
                              </template>
                              <span>Status</span>
                            </v-tooltip>
                            <div class="d-flex mt-2">
                              <v-tooltip
                                v-if="data?.quotation_convt_date"
                                top
                                content-class="custom-top-tooltip"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    label
                                    small
                                    color="teal lighten-1 white--text"
                                    class="ml-2 text-uppercase font-weight-600"
                                    >Q</v-chip
                                  >
                                </template>
                                <span>Quotation Converted</span>
                              </v-tooltip>
                              <v-tooltip
                                v-if="data?.invoice_convt_date"
                                top
                                content-class="custom-top-tooltip"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    label
                                    small
                                    color="lime darken-3 white--text"
                                    class="ml-2 text-uppercase font-weight-600"
                                    >I</v-chip
                                  >
                                </template>
                                <span>Invoice Converted</span>
                              </v-tooltip>
                              <v-tooltip
                                v-if="data?.visit_convt_date"
                                top
                                content-class="custom-top-tooltip"
                              >
                                <template v-slot:activator="{ on, attrs }">
                                  <v-chip
                                    v-bind="attrs"
                                    v-on="on"
                                    label
                                    small
                                    color="brown darken-2 white--text"
                                    class="ml-2 text-uppercase font-weight-600"
                                    >V</v-chip
                                  >
                                </template>
                                <span>Visit Converted</span>
                              </v-tooltip>
                            </div>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'site_info'">
                          <div class="enquiry-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Location Name: </b>
                              <show-value
                                :object="data"
                                object-key="property_relation.property_name"
                                label="location name"
                              />
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Location: </b>
                              <show-value
                                :object="data"
                                object-key="property_relation.property_address"
                                label="location"
                              />
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by_relation)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by_relation.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.created_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatDateStartTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by_relation)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by_relation.display_name }}
                            </template>
                            <template v-slot:date_time>
                              {{ data.modified_at }}
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td :colspan="customThead.length" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no enquiry at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Enquiry</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <v-col md="6">
                    <v-menu
                      v-model="datePicker"
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="290px"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          dense
                          filled
                          flat
                          label="Dates Range"
                          hide-details
                          solo
                          clearable
                          v-on:click:clear="dates = []"
                          prepend-inner-icon="mdi-calendar"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                          :value="formattedDate"
                          color="cyan"
                        ></v-text-field>
                      </template>
                      <v-date-picker
                        color="cyan"
                        range
                        v-model="dates"
                      ></v-date-picker>
                    </v-menu>
                  </v-col>

                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <Datepicker
                        v-if="field == 'open_till'"
                        solo
                        flat
                        v-model.trim="listingSearch[field]"
                        label="Open Still"
                        v-on:update:date-picker="setDefaultDate($event)"
                      ></Datepicker>

                      <v-text-field
                        v-else
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="
                          field == 'sales'
                            ? 'Sales Person'
                            : field == 'customer_detail[value]'
                            ? 'Customer Details[Phone Number]'
                            : getFilterLabel(field, 'Enquiry')
                        "
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
                <CustomerListingFilter
                  v-if="false"
                  :key="`customer-listing-filter`"
                />
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <ImportTemplate
          :dialog="importDialog"
          sample-url="enquiry/import-sample"
          api-url="enquiry/import"
          title="Import Enquiry"
          v-on:close="importDialog = false"
        ></ImportTemplate>

        <template v-if="entityDialog">
          <EntityDialog
            :entity-dialog="entityDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectEntity="selectEntity"
          ></EntityDialog>
        </template>
        <template v-if="entity && customerDialog">
          <CustomerDialog
            :entity="entity"
            :customerDialog="customerDialog"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template>
        <template v-if="customerPersonDialog">
          <CustomerPersonDialog
            disabled-auto-select
            :customerPersonDialog="customerPersonDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerPerson="selectCustomerPerson"
          ></CustomerPersonDialog>
        </template>
        <template v-if="customerPropertyDialog">
          <CustomerPropertyDialog
            disabled-auto-select
            :customerPropertyDialog="customerPropertyDialog"
            :customer="customer"
            v-on:closeDialog="closeDialog"
            v-on:resetAll="resetAll"
            v-on:selectCustomerProperty="selectCustomerProperty"
          ></CustomerPropertyDialog>
        </template>
      </template>
    </ListingTemplate>
  </div>
  <!--end::Enquiry Listing-->
</template>

<script>
import ShowValue from "@/view/components/ShowValue.vue";
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import EnquiryMixin from "@/core/lib/enquiry/enquiry.mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import CustomerPersonDialog from "@/view/pages/partials/Select-Customer-Person.vue";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
// import CustomStatus from "@/view/pages/partials/CustomStatus.vue";
import EntityDialog from "@/view/pages/partials/Select-Entity.vue";
import CustomerPropertyDialog from "@/view/pages/partials/Select-Customer-Property.vue";
import CustomerListingFilter from "@/view/pages/partials/Customer-Listing-Filter.vue";
import JwtService from "@/core/services/jwt.service";
import Datepicker from "@/view/pages/partials/Datepicker.vue";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
//import { map } from "lodash";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  mixins: [CommonMixin, ListingMixin, EnquiryMixin],
  name: "enquiry-list",
  data() {
    return {
      exportLoading: false,
      dates: [],
      pageModule: "enquiry-listing",
      routeAPI: "enquiry",
      routeName: "enquiry",
      routeDetailName: "enquiry.detail",
      importDialog: false,
      status: "all",
      statusList: [],
      customThead: [],
      moreActions: [
        {
          title: "Import Enquiry(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        /*{
          title: "Export Enquiry(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      bulkActions: [
        {
          title: "Mark as Draft",
          icon: "mdi-check-all",
          action: "draft",
        },
        {
          title: "Mark as Accepted",
          icon: "mdi-check-all",
          action: "accepted",
        },
        {
          title: "Mark as Rejected",
          icon: "mdi-check-all",
          action: "rejected",
        },
        {
          title: "Mark as Pending",
          icon: "mdi-email-receive",
          action: "pending",
        },
        /*{
          title: "Send as Email",
          icon: "mdi-email-send",
          action: "sent"
        },*/
        {
          title: "Mark as Cancelled",
          icon: "mdi-check-all",
          action: "cancelled",
        },
        {
          title: "Mark as Closed",
          icon: "mdi-check-all",
          action: "closed",
        },
      ],
    };
  },
  components: {
    ShowValue,
    PageTips,
    draggable,
    EntityDialog,
    // CustomStatus,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    CustomerDialog,
    CustomerListingFilter,
    CustomerPropertyDialog,
    CustomerPersonDialog,
    Datepicker,
    ImportTemplate,
  },
  watch: {
    dates: {
      deep: true,
      immediate: true,
      handler(param) {
        if (param.length > 1) {
          this.listingSearch["dates"] = param;
        } else {
          this.listingSearch["dates"] = [];
        }
      },
    },
  },
  methods: {
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    selectCustomerDialog() {
      this.customerDialog = true;
    },
    selectCustomerPersonDialog() {
      this.customerPersonDialog = true;
    },
    selectCustomerPropertyDialog() {
      this.customerPropertyDialog = true;
    },
    resetAll() {
      this.entity = 1;
      this.customer = 0;
      this.customerPerson = 0;
      this.customerProperty = 0;
      this.closeDialog();
    },
    closeDialog() {
      this.entityDialog = false;
      this.customerDialog = false;
      this.customerPersonDialog = false;
      this.customerPropertyDialog = false;
    },
    selectEntity(param) {
      this.entity = param;
      this.closeDialog();
      this.$nextTick(() => {
        this.selectCustomerDialog();
      });
    },
    selectCustomer(param) {
      this.customer = param;
      this.closeDialog();
      this.checkEnquiry();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerPerson(param) {
      this.customerPerson = param;
      this.closeDialog();
      this.checkEnquiry();
      if (!this.customerProperty || this.customerProperty <= 0) {
        this.customerPropertyDialog = true;
      }
    },
    selectCustomerProperty(param) {
      this.customerProperty = param;
      this.closeDialog();
      this.checkEnquiry();
    },

    /* exportEnquiry() {
      let downloadURL = process.env.VUE_APP_API_URL + "enquiries/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      window.open(downloadURL, "_blank");
    }, */

    exportEnquiry() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "enquiries/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },

    customerRedirect(customerId) {
      this.$router.push(
        this.getDefaultRoute("customer.detail", {
          params: { id: customerId },
        })
      );
    },
    checkEnquiry() {
      const _this = this;
      if (_this.customer > 0 && _this.customerProperty > 0) {
        _this.$router.push(
          _this.getDefaultRoute("enquiry.create", {
            query: {
              customer: _this.customer ?? undefined,
              property: _this.customerProperty ?? undefined,
            },
          })
        );
      }
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkEnquiryExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("enquiry");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateBulkAction(param) {
      const _this = this;
      switch (param) {
        case "draft":
          _this.bulkEnquiryUpdate({
            enquiries: _this.selectedRows,
            status: 1,
          });
          break;
        case "accepted":
          _this.bulkEnquiryUpdate({
            enquiries: _this.selectedRows,
            status: 2,
          });
          break;
        case "rejected":
          _this.bulkEnquiryUpdate({
            enquiries: _this.selectedRows,
            status: 3,
          });
          break;
        case "pending":
          _this.bulkEnquiryUpdate({
            enquiries: _this.selectedRows,
            status: 4,
          });
          break;
        case "sent":
          _this.bulkEnquiryUpdate({
            enquiries: _this.selectedRows,
            status: 5,
          });
          break;
        case "cancelled":
          _this.bulkEnquiryUpdate({
            enquiries: _this.selectedRows,
            status: 6,
          });
          break;
        case "closed":
          _this.bulkEnquiryUpdate({
            enquiries: _this.selectedRows,
            status: 7,
          });
          break;
      }
    },
    bulkEnquiryUpdate(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "enquiry",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkEnquiryExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "enquiries/export",
        })
        .then(({ data }) => {
          saveAs(data, "enquiries-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.resetAll();
    this.$store.dispatch(SET_BREADCRUMB, [
      {
        title: "Enquiry",
      },
    ]);
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    // _this.defaultColDefs = [
    //   /* {
    //     headerName: "",
    //     field: "id",
    //     sort: null,
    //     visible: true,
    //     fixed: true,
    //     sortable: false,
    //     checkbox: true,
    //     order: 0,
    //     width: "50px",
    //   }, */
    //   {
    //     headerName: "Enquiry #",
    //     field: "barcode",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Job Title / Company Address",
    //     field: "job_title",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Basic Details",
    //     field: "basic_detail",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Dates",
    //     field: "date",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 1,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Customer Info",
    //     field: "company_info",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "300px",
    //   },
    //   {
    //     headerName: "Status",
    //     field: "status",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Amount",
    //     field: "amount",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 3,
    //     width: "200px",
    //   },
    //   {
    //     headerName: "Created Time",
    //     child: "created_at",
    //     field: "added_at",
    //     sort: null,
    //     visible: true,
    //     fixed: false,
    //     sortable: false,
    //     order: 9,
    //     width: "170px",
    //   },
    //   {
    //     headerName: "Last Modified Time",
    //     child: "modified_at",
    //     field: "updated_at",
    //     sort: null,
    //     visible: false,
    //     fixed: false,
    //     sortable: false,
    //     order: 10,
    //     width: "170px",
    //   },
    // ];

    // const defaultColDefs = _this.lodash.filter(_this.defaultColDefs, {
    //   visible: true,
    // });

    // _this.defaultColShow = defaultColDefs.map((col) => col.field);

    _this.status = _this.$route.query.status || _this.status;

    //_this.applyColState();

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
  computed: {
    ...mapGetters(["vCustomerFilterKey"]),
  },
};
</script>
